import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
//import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    //onSearchSubmit,
    //initialSearchFormValues,
    onClickInvite,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className, 'container');

  /*  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );
*/
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const notificationDotinline =
    notificationCount > 0 ? <div className={css.notificationDotinline} /> : null;

  /*  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;
*/

  const inviteNewMember = authenticatedOnClientSide ? (
    <button className={css.inviteLink} onClick={onClickInvite}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.invite" />
      </span>
    </button>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.myMenu}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent
        className={css.profileMenuContent}
        useCustomStyles={true}
        arrowPosition={0}
        style={{ right: 0 }}
      >
        <MenuItem key="Inbox">
          <NamedLink
            className={css.inboxLink}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <span>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDotinline}
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink className={classNames(css.yourListingsLink)} name="ManageListingsPage">
            <span className={css.menuItemBorder} />
            {currentUserListingFetched && currentUserListing ? (
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            ) : null}
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="tapkey">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('TapkeyPage'))}
            name="TapkeyPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.tapkey" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const usableListingsLink =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
      <NamedLink className={css.usableListingsLink} name="SearchPage">
        <span className={classNames(css.linkText, css.callToAction)}>
          <FormattedMessage id="TopbarDesktop.usableListingsLink" />
        </span>
      </NamedLink>
    ) : authenticatedOnClientSide && currentUserListingFetched ? (
      <NamedLink className={css.usableListingsLink} name="SearchPage">
        <span className={classNames(css.linkText)}>
          <FormattedMessage id="TopbarDesktop.usableListingsLink" />
        </span>
      </NamedLink>
    ) : null;

  const createListingLink =
    isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={classNames(css.linkText, css.callToAction)}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    );

  return (
    <nav className={classes}>
      {currentPage === null ? (
        ''
      ) : (
        <div className={css.betaHintDesktop}>
          Diese App befindet sich in der Testphase - bei Problemen oder Hinweisen wenden Sie sich
          bitte an <a href="mailto:testen@sharepar.com">testen@sharepar.com</a>
        </div>
      )}
      {currentPage === null ? (
        ''
      ) : (
        <div className={css.betaHintMobile}>
          <NamedLink name="LandingPage">Diese App befindet sich in der Testphase</NamedLink>
        </div>
      )}
      <div className={css.myNavWrapper}>
        <div className={css.myLogoWrapper}>
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </NamedLink>
        </div>
        <div className={css.myMenuWrapper_left}>
          <div className={css.authenticatedLinksHolder}>
            {createListingLink}
            {inviteNewMember}
            {usableListingsLink}
          </div>
        </div>
        <div className={css.myMenuWrapper_right}>
          {/*search*/}
          {/*inboxLink*/}
          <div className={css.avatarHolder}>
            {profileMenu}
            {notificationDot}
          </div>
          {signupLink}
          {loginLink}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  onClickInvite: func,
};

export default TopbarDesktop;
